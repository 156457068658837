export const draw11Info = {
    '0影': {
        basicAtk: 888,
        critical: 0.05 * 2 + 0.5 + 0.048 * 2 + 0.048 * 2 + 0.048 * 2,
        damageRate: {
            'a': 1 + 0.7 + 0.1 + 0.225,
            'e': 1 + 0.1 + 0.225,
            'q': 1 + 0.1 + 0.225,
            'z': 1 + 0.1 + 0.225,
        },
    },
    '2影': {
        basicAtk: 888,
        critical: 0.05 * 2 + 0.5 + 0.048 * 2 + 0.048 * 2 + 0.048 * 2,
        damageRate: {
            'a': 1 + 0.7 + 0.1 + 0.225 + 0.03 * 12,
            'e': 1 + 0.1 + 0.225,
            'q': 1 + 0.1 + 0.225,
            'z': 1 + 0.1 + 0.225,
        },
    },
    '6影': {
        basicAtk: 888,
        critical: 0.05 * 2 + 0.5 + 0.048 * 2 + 0.048 * 2 + 0.048 * 2,
        resistance: {
            'a': -0.25,
        },
        damageRate: {
            'a': 1 + 0.7 + 0.1 + 0.225 + 0.03 * 12,
            'e': 1 + 0.1 + 0.225,
            'q': 1 + 0.1 + 0.225,
            'z': 1 + 0.1 + 0.225,
        },
    },
};

export const engine11Info = {
    '1阶硫磺石': {
        basicAtk: 684,
        outerAtkRate: 0.3,
        innerAtkRate: 0.28,
        critical: 0,
        resistance: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
        damageRate: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
    },
    '5阶硫磺石': {
        basicAtk: 684,
        outerAtkRate: 0.3,
        innerAtkRate: 0.56,
        critical: 0,
        resistance: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
        damageRate: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
    },
    '1阶心弦': {
        basicAtk: 713,
        outerAtkRate: 0,
        innerAtkRate: 0,
        critical: 0.98,
        resistance: {
            a: 0,
            e: 0,
            'q': -0.25,
            'z': -0.25,
        },
        damageRate: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
    },
    '5阶心弦': {
        basicAtk: 713,
        outerAtkRate: 0,
        innerAtkRate: 0,
        critical: 1.28,
        resistance: {
            a: 0,
            e: 0,
            'q': -0.4,
            'z': -0.4,
        },
        damageRate: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
    },
    '5阶星辉': {
        basicAtk: 594,
        outerAtkRate: 0.25,
        innerAtkRate: 0.192,
        critical: 0,
        resistance: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
        damageRate: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
    },
    '5阶加农': {
        basicAtk: 594,
        outerAtkRate: 0.12,
        innerAtkRate: 0,
        critical: 0.2 * 2,
        resistance: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
        damageRate: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
    },
    '5阶鎏金': {
        basicAtk: 594,
        outerAtkRate: 0.25 + 0.096,
        innerAtkRate: 0,
        critical: 0,
        resistance: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
        damageRate: {
            a: 0,
            e: 0.24,
            q: 0,
            z: 0,
        },
    },
    '1阶青囊': {
        basicAtk: 713,
        outerAtkRate: 0,
        innerAtkRate: 0,
        critical: 0.48 + 0.1 * 2 + 0.1 * 2,
        resistance: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
        damageRate: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
    },
    '5阶青囊': {
        basicAtk: 713,
        outerAtkRate: 0,
        innerAtkRate: 0,
        critical: 0.48 + 0.16 * 2 + 0.6 * 2,
        resistance: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
        damageRate: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
    },
    '5阶强音': {
        basicAtk: 594,
        outerAtkRate: 0,
        innerAtkRate: 0.12 * 2,
        critical: 0.2 * 2,
        resistance: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
        damageRate: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
    },
    '1阶防暴者': {
        basicAtk: 713,
        outerAtkRate: 0,
        innerAtkRate: 0,
        critical: 0.48 + 0.15 * 2,
        resistance: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
        damageRate: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
    },
    '5阶防暴者': {
        basicAtk: 713,
        outerAtkRate: 0,
        innerAtkRate: 0,
        critical: 0.48 + 0.3 * 2,
        resistance: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
        damageRate: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
    },
    '1阶深海': {
        basicAtk: 713,
        outerAtkRate: 0,
        innerAtkRate: 0,
        critical: 0.24 * 2 + 0.1 * 2,
        resistance: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
        damageRate: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
    },
    '5阶深海': {
        basicAtk: 713,
        outerAtkRate: 0,
        innerAtkRate: 0,
        critical: 0.24 * 2 + 0.2 * 2,
        resistance: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
        damageRate: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
    },
    '1阶钢铁': {
        basicAtk: 684,
        outerAtkRate: 0,
        innerAtkRate: 0,
        critical: 0.24 * 2,
        resistance: {
            a: 0,
            e: 0,
            q: 0,
            z: 0,
        },
        damageRate: {
            a: 0.25,
            e: 0.25,
            q: 0.25,
            z: 0.25,
        },
    },
};

export const driver11Info = {
    '2鸟+4火': {
        outerAtk: 316,
        outerAtkRate: 0.3,
        critical: 0.08 * 2 + 0.28 * 2 + 0.48,
        damageRate: {
            'a': 0.1,
            'e': 0.1,
            'q': 0.1,
            'z': 0.1,
        },
    },
    '4鸟+2火': {
        outerAtk: 316,
        outerAtkRate: 0.3,
        innerAtkRate: 0.09 * 3,
        critical: 0.08 * 2 + 0.48,
        damageRate: {
            'a': 0.1,
            'e': 0.1,
            'q': 0.1,
            'z': 0.1,
        },
    },
    '4鸟+2折': {
        outerAtk: 316,
        outerAtkRate: 0.3,
        innerAtkRate: 0.09 * 3,
        critical: 0.08 * 2 + 0.48 + 0.16,
        damageRate: {
            'a': 0,
            'e': 0,
            'q': 0,
            'z': 0,
        },
    },
    '2鸟+2火+2折': {
        outerAtk: 316,
        outerAtkRate: 0.3,
        critical: 0.08 * 2 + 0.16 + 0.48,
        damageRate: {
            'a': 0.1,
            'e': 0.1,
            'q': 0.1,
            'z': 0.1,
        },
    },
    '2鸟+4河': {
        outerAtk: 316,
        outerAtkRate: 0.3,
        innerAtkRate: 0.15,
        critical: 0.08 * 2,
        damageRate: {
            'a': 0,
            'e': 0,
            'q': 0.2,
            'z': 0,
        },
    },
    '2火+4河': {
        outerAtk: 316,
        outerAtkRate: 0.3,
        innerAtkRate: 0.15,
        critical: 0,
        damageRate: {
            'a': 0.1,
            'e': 0.1,
            'q': 0.1 + 0.2,
            'z': 0.1,
        },
    },
    '2折+4河': {
        outerAtk: 316,
        outerAtkRate: 0.3,
        innerAtkRate: 0.15,
        critical: 0.08 * 2,
        damageRate: {
            'a': 0,
            'e': 0,
            'q': 0.2,
            'z': 0,
        },
    },
    '2鸟+2火+2河': {
        outerAtk: 316,
        outerAtkRate: 0.3,
        critical: 0.08 * 2 + 0.48,
        damageRate: {
            'a': 0.1,
            'e': 0.1,
            'q': 0.1,
            'z': 0.1,
        },
    },
    '2鸟+2折+2河': {
        outerAtk: 316,
        outerAtkRate: 0.3,
        critical: 0.08 * 2 + 0.16 + 0.48,
    },
};

export const driver5placeInfo = {
    '5号位火伤': {
        damageRate: {
            'a': 0.3,
            'e': 0.3,
            'q': 0.3,
            'z': 0.3,
        },
    },
    '5号位攻击': {
        outerAtkRate: 0.3,
    },
    '5号位穿透': {

    },
}

export const teammatesInfo = {
    '凯撒': {
        draw: {
            '0影': {
                innerAtk: 1000,
                damageRate: {
                    'a': 0.25,
                    'e': 0.25,
                    'q': 0.25,
                    'z': 0.25,
                },
            },
            '1影': {
                innerAtk: 1000,
                damageRate: {
                    'a': 0.25,
                    'e': 0.25,
                    'q': 0.25,
                    'z': 0.25,
                },
                resistance: {
                    'a': -0.15,
                    'e': -0.15,
                    'q': -0.15,
                    'z': -0.15,
                },
            },
            '2影': {
                innerAtk: 1500,
                damageRate: {
                    'a': 0.25,
                    'e': 0.25,
                    'q': 0.25,
                    'z': 0.25,
                },
                resistance: {
                    'a': -0.15,
                    'e': -0.15,
                    'q': -0.15,
                    'z': -0.15,
                },
            },
        },
        engine: {
            '非专武': {
            },
            '专武1阶': {
                damageRate: {
                    'a': 0.18,
                    'e': 0.18,
                    'q': 0.18,
                    'z': 0.18,
                },
            },
        },
    },
    '莱特': {
        draw: {
            '0影': {
                resistance: {
                    'a': -0.15,
                    'e': -0.15,
                    'q': -0.15,
                    'z': -0.15,
                },
                damageRate: {
                    'a': 0.75,
                    'e': 0.75,
                    'q': 0.75,
                    'z': 0.75,
                },
            },
            '1影': {
                resistance: {
                    'a': -0.25,
                    'e': -0.25,
                    'q': -0.25,
                    'z': -0.25,
                },
                damageRate: {
                    'a': 0.75,
                    'e': 0.75,
                    'q': 0.75,
                    'z': 0.75,
                },
            },
            '2影': {
                resistance: {
                    'a': -0.25,
                    'e': -0.25,
                    'q': -0.25,
                    'z': -0.25,
                },
                damageRate: {
                    'a': 0.9,
                    'e': 0.9,
                    'q': 0.9,
                    'z': 0.9,
                },
            },
        },
        engine: {
            '非专武': {
            },
            '专武1阶': {
                critical: 0.3,
            },
        },
    },
    '耀嘉音': {
        draw: {
            '0影': {
                innerAtk: 1200,
                critical: 0.25,
                damageRate: {
                    'a': 0.44,
                    'e': 0.44,
                    'q': 0.44,
                    'z': 0.44,
                },
            },
            '1影': {
                innerAtk: 1200,
                critical: 0.25,
                resistance: {
                    'a': -0.18,
                    'e': -0.18,
                    'q': -0.18,
                    'z': -0.18,
                },
                damageRate: {
                    'a': 0.44,
                    'e': 0.44,
                    'q': 0.44,
                    'z': 0.44,
                },
            },
            '2影': {
                innerAtk: 1600,
                critical: 0.25,
                resistance: {
                    'a': -0.18,
                    'e': -0.18,
                    'q': -0.18,
                    'z': -0.18,
                },
                damageRate: {
                    'a': 0.44,
                    'e': 0.44,
                    'q': 0.44,
                    'z': 0.44,
                },
            },
        },
        engine: {
            '非专武': {
            },
            '专武1阶': {
                damageRate: {
                    'a': 0.2,
                    'e': 0.2,
                    'q': 0.2,
                    'z': 0.2,
                },
            },
        }
    },
    '露西': {
        draw: {
            '0影': {
                innerAtk: 600,
            },
            '4影': {
                innerAtk: 600,
                critical: 0.1,
            },
        },
        engine: {
            '专武1阶': {
                innerAtkRate: 0.025 * 4,
            },
            '专武5阶': {
                innerAtkRate: 0.04 * 4,
            },
        }
    },
    '妮可': {
        draw: {
            '0影': {
            },
            '6影': {
                critical: 0.15 * 2,
            },
        },
        engine: {
            '专武1阶': {
                damageRate: {
                    'a': 0.15,
                    'e': 0.15,
                    'q': 0.15,
                    'z': 0.15,
                },
            },
            '专武5阶': {
                damageRate: {
                    'a': 0.24,
                    'e': 0.24,
                    'q': 0.24,
                    'z': 0.24,
                },
            },
        }
    },
    '柯蕾达': {
        draw: {
            '0影': {
                damageRate: {
                    z: 0.35 * 2,
                },
            },
        },
        engine: {
            '非专武': {
            },
        }
    },
}