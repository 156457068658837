const data = [{
    key: '5.3_spiral_abyss_upper',
    name: '5.3深渊上半',
    time: '2025-01-16~2025-02-15',
    scores: [
        {
            uid: '515292393',
            name: '烟绯三月夏扬州',
            team: [
                ['烟绯', 6, '万世流涌大典', 1],
                ['芙宁娜', 2, '西风剑', 5],
                ['茜特菈莉', 2, '祭星者之望', 1],
                ['希诺宁', 3, '西风剑', 5],
            ],
            time: 101,
            cost: 12,
            video: 'https://www.bilibili.com/video/BV1yuwMedEWX/',
        },
        {
            uid: '117979290',
            name: '倒立享受人参',
            team: [
                ['烟绯', 6, '万世流涌大典', 1],
                ['芙宁娜', 3, '西风剑', 5],
                ['茜特菈莉', 6, '祭星者之望', 1],
                ['班尼特', 6, '风鹰剑', 1],
            ],
            time: 88,
            cost: 14,
            video: 'https://www.bilibili.com/video/BV1hkwWeJEqN/',
        },
        {
            uid: '103785209',
            name: '-临夜-',
            team: [
                ['烟绯', 6, '流浪乐章', 5],
                ['希诺宁', 6, '岩峰巡歌', 5],
                ['茜特菈莉', 6, '祭星者之望', 5],
                ['班尼特', 6, '雾切之回光', 5],
            ],
            time: 31,
            cost: 29,
            video: 'https://www.bilibili.com/video/BV1TZPBepEw6/',
        },
        {
            uid: '173898921',
            name: '盉本空',
            team: [
                ['烟绯', 6, '流浪乐章', 5],
                ['砂糖', 6, '祭礼残章', 5],
                ['行秋', 6, '西风剑', 5],
                ['班尼特', 6, '暗巷闪光', 5],
            ],
            time: 219,
            cost: 0,
            video: 'https://www.bilibili.com/video/BV1BcwaeJE6F/',
        },
        {
            uid: '173898921',
            name: '和彩彩',
            team: [
                ['烟绯', 6, '万世流涌大典', 5],
                ['希诺宁', 6, '岩峰巡歌', 5],
                ['茜特菈莉', 6, '祭星者之望', 5],
                ['芙宁娜', 6, '圣显之钥', 5],
            ],
            time: 43,
            cost: 41,
            video: 'https://www.bilibili.com/video/BV1BEPXeLE2D/',
        },
    ],
}, {
    key: '5.4_spiral_abyss_upper',
    name: '5.4深渊上半',
    time: '2025-02-16~2025-03-15',
    scores: [
        {
            uid: '515292393',
            name: '烟绯三月夏扬州',
            team: [
                ['烟绯', 6, '万世流涌大典', 1],
                ['芙宁娜', 2, '西风剑', 5],
                ['茜特菈莉', 2, '祭星者之望', 1],
                ['希诺宁', 3, '西风剑', 5],
            ],
            time: 139,
            cost: 12,
            video: 'https://www.bilibili.com/video/BV1wWAueFERC/',
        },
        {
            uid: '117979290',
            name: '倒立享受人参',
            team: [
                ['烟绯', 6, '万世流涌大典', 1],
                ['芙宁娜', 3, '西风剑', 5],
                ['茜特菈莉', 6, '祭星者之望', 1],
                ['班尼特', 3, '雾切之回光', 1],
            ],
            time: 79,
            cost: 14,
            video: 'https://www.bilibili.com/video/BV1GmA3eHEAU/',
        },
        {
            uid: '103785209',
            name: '-临夜-',
            team: [
                ['烟绯', 6, '流浪乐章', 5],
                ['希诺宁', 6, '岩峰巡歌', 5],
                ['茜特菈莉', 6, '祭星者之望', 5],
                ['班尼特', 6, '雾切之回光', 5],
            ],
            time: 38,
            cost: 29,
            video: 'https://www.bilibili.com/video/BV1PDAxe1ECH/',
        },
        {
            uid: '515292393',
            name: '烟绯三月夏扬州',
            team: [
                ['烟绯', 6, '金流监督', 5],
                ['芙宁娜', 6, '静水流涌之辉', 4],
                ['茜特菈莉', 2, '祭星者之望', 1],
                ['希诺宁', 3, '西风剑', 5],
            ],
            time: 93,
            cost: 24,
            video: 'https://www.bilibili.com/video/BV1d19HYFE1M/',
        },
        {
            uid: '203015130',
            name: '-思夏忆欢-',
            team: [
                ['烟绯', 6, '流浪乐章', 5],
                ['芙宁娜', 6, '静水流涌之辉', 1],
                ['枫原万叶', 2, '西风剑', 5],
                ['班尼特', 6, '天空之刃', 5],
            ],
            time: 132,
            cost: 12,
            video: 'https://www.bilibili.com/video/BV1S3RNYGEzi/',
        },
    ],
}, {
    key: '5.4_spiral_abyss_lower',
    name: '5.4深渊下半',
    time: '2025-02-16~2025-03-15',
    scores: [
        {
            uid: '114460799',
            name: '捡起肥皂硬又黑',
            team: [
                ['烟绯', 6, '流浪乐章', 5],
                ['芙宁娜', 6, '圣显之钥', 1],
                ['茜特菈莉', 6, '祭星者之望', 1],
                ['闲云', 2, '鹤鸣余音', 1],
            ],
            time: 48,
            cost: 20,
            video: 'https://www.bilibili.com/video/BV1BZwSeNEyc/',
        },
        {
            uid: '117979290',
            name: '倒立享受人参',
            team: [
                ['烟绯', 6, '万世流涌大典', 1],
                ['芙宁娜', 3, '西风剑', 5],
                ['茜特菈莉', 6, '祭星者之望', 1],
                ['班尼特', 3, '雾切之回光', 1],
            ],
            time: 78,
            cost: 14,
            video: 'https://www.bilibili.com/video/BV1eDwXeiE3B/',
        },
    ],
}, {
    key: '5.4_spiral_abyss_upper_2',
    name: '5.4深渊二期上半',
    time: '2025-03-16~2025-04-15',
    scores: [
        {
            uid: '103785209',
            name: '-临夜-',
            team: [
                ['烟绯', 6, '流浪乐章', 5],
                ['希诺宁', 6, '岩峰巡歌', 5],
                ['茜特菈莉', 6, '祭星者之望', 5],
                ['芙宁娜', 6, '圣显之钥', 5],
            ],
            time: 68,
            cost: 41,
            video: 'https://www.bilibili.com/video/BV17aQxYBE35/',
        },
        {
            uid: '515292393',
            name: '烟绯三月夏扬州',
            team: [
                ['烟绯', 6, '金流监督', 5],
                ['芙宁娜', 6, '静水流涌之辉', 4],
                ['茜特菈莉', 2, '祭星者之望', 1],
                ['希诺宁', 3, '西风剑', 5],
            ],
            time: 139,
            cost: 24,
            video: 'https://www.bilibili.com/video/BV1d19HYFE1M/',
        },
        {
            uid: '203015130',
            name: '-思夏忆欢-',
            team: [
                ['烟绯', 6, '金流监督', 1],
                ['芙宁娜', 6, '静水流涌之辉', 1],
                ['枫原万叶', 2, '西风剑', 5],
                ['行秋', 6, '祭礼剑', 5],
            ],
            time: 166,
            cost: 12,
            video: 'https://www.bilibili.com/video/BV1G6QxYAEVJ/',
        },
        {
            uid: '117979290',
            name: '倒立享受人参',
            team: [
                ['烟绯', 6, '万世流涌大典', 1],
                ['芙宁娜', 6, '磐岩结绿', 1],
                ['茜特菈莉', 6, '祭星者之望', 1],
                ['枫原万叶', 2, '苍古自由之誓', 1],
            ],
            time: 118,
            cost: 21,
            video: 'https://www.bilibili.com/video/BV126QhYqEBH/',
        },
    ],
}, {
    key: '5.4_spiral_abyss_lower_2',
    name: '5.4深渊二期下半',
    time: '2025-03-16~2025-04-15',
    scores: [
        {
            uid: '114460799',
            name: '捡起肥皂硬又黑',
            team: [
                ['烟绯', 6, '流浪乐章', 5],
                ['班尼特', 6, '雾切之回光', 1],
                ['茜特菈莉', 6, '祭星者之望', 1],
                ['闲云', 2, '鹤鸣余音', 1],
            ],
            time: 29,
            cost: 12,
            video: 'https://www.bilibili.com/video/BV1J6XKYsEVm/',
        },
        {
            uid: '114460799',
            name: '捡起肥皂硬又黑',
            team: [
                ['烟绯', 6, '流浪乐章', 5],
                ['班尼特', 6, '雾切之回光', 1],
                ['茜特菈莉', 6, '祭星者之望', 1],
                ['闲云', 2, '鹤鸣余音', 1],
            ],
            time: 33,
            cost: 12,
            video: 'https://www.bilibili.com/video/BV19xQbYuEpo/',
        },
        {
            uid: '114460799',
            name: '捡起肥皂硬又黑',
            team: [
                ['烟绯', 6, '流浪乐章', 5],
                ['芙宁娜', 6, '圣显之钥', 1],
                ['茜特菈莉', 6, '祭星者之望', 1],
                ['闲云', 2, '鹤鸣余音', 1],
            ],
            time: 39,
            cost: 20,
            video: 'https://bilibili.com/video/BV19xQbYuEpo/',
        },
        {
            uid: '515292393',
            name: '烟绯三月夏扬州',
            team: [
                ['烟绯', 6, '金流监督', 5],
                ['芙宁娜', 6, '静水流涌之辉', 4],
                ['茜特菈莉', 2, '祭星者之望', 1],
                ['希诺宁', 3, '西风剑', 5],
            ],
            time: 73,
            cost: 24,
            video: 'https://www.bilibili.com/video/BV12CQhYvEsN/',
        },
    ],
}];

export default data;