import React, { useEffect, useState } from 'react';
import { Row, Col, Select, InputNumber, Switch, notification } from 'antd';
import { cloneDeep } from 'lodash';
import { yanfeiMz, yanfeiSyw, yanfeiWeapon, teammates } from '../../yanfei.data';
import styles from './index.module.css';

const Yanfei = () => {
    const [api, contextHolder] = notification.useNotification();
    const [zhuozhuo, setZhuozhuo] = useState(true);
    const [damageCup, setDamageCup] = useState(false);
    const [criticalBalance, setCriticalBalance] = useState(true);
    const [yanfeiAttr, setYanfeiAttr] = useState({
        atk: 2551,
        elemental: 161,
        criticalRate: 62.4,
        criticalDamage: 188.3,
    });
    const [yanfeiConfig, setYanfeiConfig] = useState({
        mz: '6命',
        syw: '4猎人',
        weapon: '精1金流',
    });
    const [teammatesConfig, setTeammatesConfig] = useState([]);
    const [enemyConfig, setEnemyConfig] = useState({
        level: 100,
        resistance: 10,
    });

    // 定义最终属性
    const finalAttr = {
        // 基础攻击力
        basicAtk: yanfeiMz[yanfeiConfig.mz].basicAtk,
        // 额外大攻击
        atkRate: 0,
        // 基础倍率
        basicRate: yanfeiMz[yanfeiConfig.mz].basicRate,
        // 基础乘区补足
        basicCount: 0,
        // 增幅额外倍率
        elementalRate: 1,
    };
    // 烟绯自身属性（攻击力，精通，暴击，暴伤）
    let {
        atk,
        elemental,
        criticalRate,
        criticalDamage,
    } = yanfeiAttr;
    finalAttr.atk = atk;
    finalAttr.elemental = elemental;
    finalAttr.criticalRate = criticalRate / 100;
    finalAttr.criticalDamage = criticalDamage / 100;
    // finalAttr.criticalScore = (criticalRate * 2 + criticalDamage) / 100;
    finalAttr.damageRate = cloneDeep(yanfeiMz[yanfeiConfig.mz].damageRate);
    if (zhuozhuo) {
        finalAttr.damageRate.z += yanfeiMz[yanfeiConfig.mz].zz;
    }
    // 固定防御乘区
    finalAttr.defence = 90 / (90 + enemyConfig.level);
    // 怪物抗性
    finalAttr.resistance = enemyConfig.resistance / 100;
    // 烟绯圣遗物统计
    for (const attr of Object.keys(yanfeiSyw[yanfeiConfig.syw])) {
        if (finalAttr[attr]) {
            if (typeof yanfeiSyw[yanfeiConfig.syw][attr] === 'number') {
                finalAttr[attr] += yanfeiSyw[yanfeiConfig.syw][attr];
            } else {
                for (const subAttr of Object.keys(yanfeiSyw[yanfeiConfig.syw][attr])) {
                    if (finalAttr[attr][subAttr]) {
                        finalAttr[attr][subAttr] += yanfeiSyw[yanfeiConfig.syw][attr][subAttr];
                    } else {
                        finalAttr[attr][subAttr] = cloneDeep(yanfeiSyw[yanfeiConfig.syw][attr][subAttr]);
                    }
                }
            }
        } else {
            finalAttr[attr] = cloneDeep(yanfeiSyw[yanfeiConfig.syw][attr]);
        }
    }
    // 烟绯武器统计
    for (const attr of Object.keys(yanfeiWeapon[yanfeiConfig.weapon])) {
        if (finalAttr[attr]) {
            if (typeof yanfeiWeapon[yanfeiConfig.weapon][attr] === 'number') {
                finalAttr[attr] += yanfeiWeapon[yanfeiConfig.weapon][attr];
            } else {
                for (const subAttr of Object.keys(yanfeiWeapon[yanfeiConfig.weapon][attr])) {
                    if (finalAttr[attr][subAttr]) {
                        finalAttr[attr][subAttr] += yanfeiWeapon[yanfeiConfig.weapon][attr][subAttr];
                    } else {
                        finalAttr[attr][subAttr] = cloneDeep(yanfeiWeapon[yanfeiConfig.weapon][attr][subAttr]);
                    }
                }
            }
        } else {
            finalAttr[attr] = cloneDeep(yanfeiWeapon[yanfeiConfig.weapon][attr]);
        }
    }
    for (const teammateConfig of teammatesConfig) {
        if (!teammateConfig) {
            continue;
        }
        const teammateInfo = teammates[teammateConfig.name];
        if (teammateConfig.name === '班尼特') {
            const bntBasicAtk = teammateInfo.mz[teammateConfig.mz].selfAtk + teammateInfo.weapon[teammateConfig.weapon].selfAtk;
            finalAttr.atk += bntBasicAtk * teammateInfo.mz[teammateConfig.mz].selfAtkRate;
            finalAttr.atkRate += teammateInfo.mz[teammateConfig.mz].atkRate;
            if (teammateInfo.mz[teammateConfig.mz].damageRate) {
                for (const attr of Object.keys(teammateInfo.mz[teammateConfig.mz].damageRate)) {
                    finalAttr.damageRate[attr] += teammateInfo.mz[teammateConfig.mz].damageRate[attr];
                }
            }
            for (const attr of Object.keys(teammateInfo.syw[teammateConfig.syw])) {
                if (!finalAttr[attr]) {
                    finalAttr[attr] = cloneDeep(teammateInfo.syw[teammateConfig.syw][attr]);
                } else {
                    if (typeof teammateInfo.syw[teammateConfig.syw][attr] === 'number') {
                        finalAttr[attr] += teammateInfo.syw[teammateConfig.syw][attr];
                    } else {
                        for (const subAttr of Object.keys(teammateInfo.syw[teammateConfig.syw][attr])) {
                            if (finalAttr[attr][subAttr]) {
                                finalAttr[attr][subAttr] += teammateInfo.syw[teammateConfig.syw][attr][subAttr];
                            } else {
                                finalAttr[attr][subAttr] = cloneDeep(teammateInfo.syw[teammateConfig.syw][attr][subAttr]);
                            }
                        }
                    }
                }
            }
        } else {
            const dataList = [
                cloneDeep(teammateInfo.mz[teammateConfig.mz]) || {},
                cloneDeep(teammateInfo.weapon[teammateConfig.weapon]) || {},
                cloneDeep(teammateInfo.syw[teammateConfig.syw]) || {},
            ];
            for (const data of dataList) {
                for (const attr of Object.keys(data)) {
                    if (!finalAttr[attr]) {
                        finalAttr[attr] = cloneDeep(data[attr]);
                    } else {
                        if (typeof data[attr] === 'number') {
                            finalAttr[attr] += data[attr];
                        } else {
                            for (const subAttr of Object.keys(data[attr])) {
                                if (finalAttr[attr][subAttr]) {
                                    finalAttr[attr][subAttr] += data[attr][subAttr];
                                } else {
                                    finalAttr[attr][subAttr] = cloneDeep(data[attr][subAttr]);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // 最终攻击
    finalAttr.atk += finalAttr.basicAtk * finalAttr.atkRate;
    if (damageCup) {
        finalAttr.atk -= finalAttr.basicAtk * 0.466;
        finalAttr.damageRate.a += 0.466;
        finalAttr.damageRate.e += 0.466;
        finalAttr.damageRate.q += 0.466;
        finalAttr.damageRate.z += 0.466;
        finalAttr.damageRate.x += 0.466;
    }
    // 最终增幅乘区
    finalAttr.elementalRate += finalAttr.elemental * 2.8 / (finalAttr.elemental + 1400);
    // 最终减抗乘区
    finalAttr.resistanceRate = finalAttr.resistance > 0 ? 1 - finalAttr.resistance : 1 - finalAttr.resistance / 2;
    // 最终双暴乘区和暴击伤害
    if (criticalBalance) {
        // 自动配平
        const criticalScore = finalAttr.criticalRate * 2 + finalAttr.criticalDamage;
        finalAttr.criticalCount = criticalScore >= 4 ? criticalScore - 1 : criticalScore * criticalScore / 8 + 1;
        if (criticalScore <= 4) {
            finalAttr.criticalRate = criticalScore / 4;
            finalAttr.criticalDamage = criticalScore / 2;    
        } else {
            finalAttr.criticalRate = 1;
            finalAttr.criticalDamage = criticalScore - 2;
        }
    } else {
        const criticalRate = Math.min(finalAttr.criticalRate, 1);
        const criticalDamage = finalAttr.criticalDamage;
        finalAttr.criticalCount = 1 + criticalRate * criticalDamage;
    }

    useEffect(() => {
        api.open({
            duration: 0,
            placement: 'top',
            message: '使用说明',
            description: (
                <div>
                    <div>1. 队友取班尼特时自动计算双火；</div>
                    <div>2. 计算茜特菈莉一命时取 1000 精通，计算希诺宁四命时取 3500 防御，计算芙宁娜带板砖时取 50000 生命，计算闲云时取 4500 攻击；</div>
                    <div>3. 计算圣遗物或武器时，没有自动屏蔽相同效果重复生效，例如勇者套效果无法叠加、苍古系列武器效果无法叠加，请在选择时手动规避；</div>
                    <div>4. 队友存在「闲云」或武器选择「溢彩」时计算伤害为下落伤害，否则为重击伤害；</div>
                    <div>5. 计算时丹火印自动取满；</div>
                    <div>6. 计算伤害仅为单次伤害，不考虑实际总伤与充能循环。</div>
                </div>
            ),
        });
    }, []);

    return (
        <Row gutter={24} style={{ padding: 20 }}>
            {contextHolder}
            <div className={styles.background} />
            <Col span={window.innerWidth < 1000 ? 24 : 8}>
                <div className={styles.first} style={{
                    minHeight: window.innerWidth < 1000 ? 'auto' : 'calc(100vh - 40px)',
                }}>
                    <div className={styles.title}>烟绯配置</div>
                    <div className={styles.subtitle}>
                        命座
                    </div>
                    <Select
                        value={yanfeiConfig.mz}
                        onChange={mz => setYanfeiConfig(c => ({ ...c, mz }))}
                        className={styles.select}
                        style={{ width: '100%' }}
                    >
                        {Object.keys(yanfeiMz).map(key => (
                            <Select.Option key={key} value={key}>{key}</Select.Option>
                        ))}
                    </Select>

                    <div className={styles.subtitle}>
                        武器
                    </div>
                    <Select
                        value={yanfeiConfig.weapon}
                        onChange={weapon => setYanfeiConfig(c => ({ ...c, weapon }))}
                        className={styles.select}
                        style={{ width: '100%' }}
                    >
                        {Object.keys(yanfeiWeapon).map(key => (
                            <Select.Option key={key} value={key}>{key}</Select.Option>
                        ))}
                    </Select>

                    <div className={styles.subtitle}>
                        圣遗物
                    </div>
                    <Select
                        value={yanfeiConfig.syw}
                        onChange={syw => setYanfeiConfig(c => ({ ...c, syw }))}
                        className={styles.select}
                        style={{ width: '100%' }}
                    >
                        {Object.keys(yanfeiSyw).map(key => (
                            <Select.Option key={key} value={key}>{key}</Select.Option>
                        ))}
                    </Select>

                    <div className={styles.subtitle}>
                        单人面板属性
                    </div>
                    <InputNumber
                        prefix={'攻击'}
                        min={0}
                        value={yanfeiAttr.atk}
                        onChange={atk => setYanfeiAttr(c => ({ ...c, atk }))}
                        className={styles.select}
                        style={{ width: '100%' }}
                    />
                    <InputNumber
                        prefix={'精通'}
                        min={0}
                        value={yanfeiAttr.elemental}
                        onChange={elemental => setYanfeiAttr(c => ({ ...c, elemental }))}
                        className={styles.select}
                        style={{ width: '100%' }}
                    />
                    <InputNumber
                        prefix={'暴击率'}
                        suffix={'%'}
                        min={0}
                        value={yanfeiAttr.criticalRate}
                        onChange={criticalRate => setYanfeiAttr(c => ({ ...c, criticalRate }))}
                        className={styles.select}
                        style={{ width: '100%' }}
                    />
                    <InputNumber
                        prefix={'暴击伤害'}
                        suffix={'%'}
                        min={0}
                        value={yanfeiAttr.criticalDamage}
                        onChange={criticalDamage => setYanfeiAttr(c => ({ ...c, criticalDamage }))}
                        className={styles.select}
                        style={{ width: '100%' }}
                    />
                    <div className={styles.subtitle}>
                        灼灼
                    </div>
                    <Switch checked={zhuozhuo} onChange={() => setZhuozhuo(!zhuozhuo)}>
                        灼灼
                    </Switch>
                    <div className={styles.subtitle}>
                        攻击杯转火伤杯
                    </div>
                    <Switch checked={damageCup} onChange={() => setDamageCup(!damageCup)}>
                        攻击杯转火伤杯
                    </Switch>
                    <div className={styles.subtitle}>
                        双暴自动配平
                    </div>
                    <Switch checked={criticalBalance} onChange={() => setCriticalBalance(!criticalBalance)}>
                        双暴自动配平
                    </Switch>
                </div>
            </Col>
            <Col span={window.innerWidth < 1000 ? 24 : 8}>
                <div className={styles.title}>队友配置</div>
                {
                    [0, 0, 0].map((_item, index) => {
                        const teammateConfig = teammatesConfig[index];
                        const standbyTeammateNames = Object.keys(teammates).filter(item => !teammatesConfig.map(item => item.name).includes(item));
                        return (
                            <div>
                                <div className={styles.subtitle}>
                                    队友 {index + 1}：{teammatesConfig[index]?.name || '未选择'}
                                </div>
                                <Select
                                    prefix={'角色：'}
                                    className={styles.select}
                                    style={{ width: '100%' }}
                                    value={teammatesConfig[index]?.name || '未选择'}
                                    onChange={name => {
                                        const newTeammatesConfig = [...teammatesConfig];
                                        newTeammatesConfig[index] = {
                                            name,
                                            ...teammates[name].defaultConfig,
                                        };
                                        setTeammatesConfig(newTeammatesConfig);
                                    }}
                                >
                                    {standbyTeammateNames.map(key => (
                                        <Select.Option key={key} value={key}>{key}</Select.Option>
                                    ))}
                                </Select>
                                {
                                    !teammateConfig ? null : (
                                        <>
                                            <Select
                                                prefix={'命座：'}
                                                className={styles.select}
                                                style={{ width: '100%' }}
                                                value={teammateConfig.mz}
                                                onChange={mz => {
                                                    const newTeammatesConfig = cloneDeep(teammatesConfig);
                                                    newTeammatesConfig[index].mz = mz;
                                                    setTeammatesConfig(newTeammatesConfig);
                                                }}
                                            >
                                                {Object.keys(teammates[teammateConfig.name].mz).map(key => (
                                                    <Select.Option key={key} value={key}>{key}</Select.Option>
                                                ))}
                                            </Select>
                                            <Select
                                                prefix={'武器：'}
                                                className={styles.select}
                                                style={{ width: '100%' }}
                                                value={teammateConfig.weapon}
                                                onChange={weapon => {
                                                    const newTeammatesConfig = cloneDeep(teammatesConfig);
                                                    newTeammatesConfig[index].weapon = weapon;
                                                    setTeammatesConfig(newTeammatesConfig);
                                                }}
                                            >
                                                {Object.keys(teammates[teammateConfig.name].weapon).map(key => (
                                                    <Select.Option key={key} value={key}>{key}</Select.Option>
                                                ))}
                                            </Select>
                                            <Select
                                                prefix={'圣遗物：'}
                                                className={styles.select}
                                                style={{ width: '100%' }}
                                                value={teammateConfig.syw}
                                                onChange={syw => {
                                                    const newTeammatesConfig = cloneDeep(teammatesConfig);
                                                    newTeammatesConfig[index].syw = syw;
                                                    setTeammatesConfig(newTeammatesConfig);
                                                }}
                                            >
                                                {Object.keys(teammates[teammateConfig.name].syw).map(key => (
                                                    <Select.Option key={key} value={key}>{key}</Select.Option>
                                                ))}
                                            </Select>
                                        </>
                                    )
                                }

                            </div>
                        )
                    })
                }
                <div className={styles.title}>敌人配置</div>
                <div className={styles.subtitle}>抗性</div>
                <InputNumber
                    min={-20}
                    max={100}
                    value={enemyConfig.resistance}
                    onChange={resistance => setEnemyConfig({ ...enemyConfig, resistance: resistance })}
                    suffix={'%'}
                    className={styles.select}
                    style={{ width: '100%' }}
                />
                <div className={styles.subtitle}>等级</div>
                <InputNumber
                    min={0}
                    max={110}
                    value={enemyConfig.level}
                    onChange={level => setEnemyConfig({ ...enemyConfig, level: level })}
                    className={styles.select}
                    style={{ width: '100%' }}
                />
            </Col>
            <Col span={window.innerWidth < 1000 ? 24 : 8}>
                <div className={styles.title}>面板与伤害</div>
                <div className={styles.subtitle}>
                    面板属性
                </div>
                <div className={styles.line}>
                    基础攻击：{finalAttr.basicAtk}
                </div>
                <div className={styles.line}>
                    攻击：{finalAttr.atk}
                </div>
                <div className={styles.line}>
                    精通：{finalAttr.elemental}
                </div>
                <div className={styles.line}>
                    暴击率：{(finalAttr.criticalRate * 100).toFixed(2)}%
                </div>
                <div className={styles.line}>
                    暴击伤害：{(finalAttr.criticalDamage * 100).toFixed(2)}%
                </div>
                <div className={styles.line}>
                    重击增伤：{(finalAttr.damageRate.z * 100).toFixed(2)}%
                </div>
                <div className={styles.line}>
                    下落增伤：{(finalAttr.damageRate.x * 100).toFixed(2)}%
                </div>
                <div className={styles.line}>
                    抗性：{(finalAttr.resistance * 100).toFixed(0)}%
                </div>
                <div className={styles.line}>
                    防御：{finalAttr.defence.toFixed(2)}
                </div>
                {teammatesConfig.find(item => item.name.includes('闲云')) || yanfeiConfig.weapon.includes('溢彩') ? (
                    <>
                        <div className={styles.subtitle}>
                            下落暴击伤害（蒸发）
                        </div>
                        <div className={styles.line}>
                            {((finalAttr.atk * finalAttr.basicRate.x + finalAttr.basicCount) * (1 + finalAttr.damageRate.x) * 1.5 * finalAttr.elementalRate * finalAttr.resistanceRate * finalAttr.defence * (1 + finalAttr.criticalDamage)).toFixed(0)}
                        </div>
                        <div className={styles.subtitle}>
                            下落平均伤害（蒸发）
                        </div>
                        <div className={styles.line}>
                            {((finalAttr.atk * finalAttr.basicRate.x + finalAttr.basicCount) * (1 + finalAttr.damageRate.x) * 1.5 * finalAttr.elementalRate * finalAttr.resistanceRate * finalAttr.defence * finalAttr.criticalCount).toFixed(0)}
                        </div>
                        <div className={styles.subtitle}>
                            下落暴击伤害（融化）
                        </div>
                        <div className={styles.line}>
                            {((finalAttr.atk * finalAttr.basicRate.x + finalAttr.basicCount) * (1 + finalAttr.damageRate.x) * 2 * finalAttr.elementalRate * finalAttr.resistanceRate * finalAttr.defence * (1 + finalAttr.criticalDamage)).toFixed(0)}
                        </div>
                        <div className={styles.subtitle}>
                            下落平均伤害（融化）
                        </div>
                        <div className={styles.line}>
                            {((finalAttr.atk * finalAttr.basicRate.x + finalAttr.basicCount) * (1 + finalAttr.damageRate.x) * 2 * finalAttr.elementalRate * finalAttr.resistanceRate * finalAttr.defence * finalAttr.criticalCount).toFixed(0)}
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.subtitle}>
                            重击暴击伤害（蒸发）
                        </div>
                        <div className={styles.line}>
                            {((finalAttr.atk * finalAttr.basicRate.z + finalAttr.basicCount) * (1 + finalAttr.damageRate.z) * 1.5 * finalAttr.elementalRate * finalAttr.resistanceRate * finalAttr.defence * (1 + finalAttr.criticalDamage)).toFixed(0)}
                        </div>
                        <div className={styles.subtitle}>
                            重击平均伤害（蒸发）
                        </div>
                        <div className={styles.line}>
                            {((finalAttr.atk * finalAttr.basicRate.z + finalAttr.basicCount) * (1 + finalAttr.damageRate.z) * 1.5 * finalAttr.elementalRate * finalAttr.resistanceRate * finalAttr.defence * finalAttr.criticalCount).toFixed(0)}
                        </div>
                        <div className={styles.subtitle}>
                            重击暴击伤害（融化）
                        </div>
                        <div className={styles.line}>
                            {((finalAttr.atk * finalAttr.basicRate.z + finalAttr.basicCount) * (1 + finalAttr.damageRate.z) * 2 * finalAttr.elementalRate * finalAttr.resistanceRate * finalAttr.defence * (1 + finalAttr.criticalDamage)).toFixed(0)}
                        </div>
                        <div className={styles.subtitle}>
                            重击平均伤害（融化）
                        </div>
                        <div className={styles.line}>
                            {((finalAttr.atk * finalAttr.basicRate.z + finalAttr.basicCount) * (1 + finalAttr.damageRate.z) * 2 * finalAttr.elementalRate * finalAttr.resistanceRate * finalAttr.defence * finalAttr.criticalCount).toFixed(0)}
                        </div>
                    </>
                )}
            </Col>
        </Row>
    );
};

export default Yanfei;