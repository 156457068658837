import React, { useState } from 'react';
import { Layout, Menu, Table, Button } from 'antd';
import dayjs from 'dayjs';
import Team from '../../components/Team/index.jsx';
import data from '../../data.js';
import styles from './index.module.css';

data.sort((a, b) => dayjs(b.time.split('~')[0]) - dayjs(a.time.split('~')[0]));

const { Sider, Content } = Layout;

const Rank = () => {
    const [selectedItem, setSelectedItem] = useState(data[0]);

    const handleMenuClick = (key) => {
        const selected = data.find((item) => item.key === key);
        setSelectedItem(selected);
    };

    const columns = [
        { title: 'UID', dataIndex: 'uid', key: 'uid', align: 'center' },
        { title: '用户昵称', dataIndex: 'name', key: 'name', align: 'center' },
        {
            title: '队伍', dataIndex: 'team', key: 'team', align: 'center', render: (text) => (
                <Team data={text} />
            )
        },
        { title: '耗时（s）', dataIndex: 'time', key: 'time', align: 'center' },
        { title: '金数', dataIndex: 'cost', key: 'cost', align: 'center' },
        {
            title: '视频链接',
            dataIndex: 'video',
            key: 'video',
            align: 'center',
            render: (text) => (
                <Button type="link" href={text} target="_blank">
                    查看视频
                </Button>
            ),
        },
    ];

    return (
        <>
            <header className={styles.header} />
            <Layout>
                <Sider width={200} style={{ background: '#fff' }}>
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={[selectedItem.key]}
                        style={{ height: '100%', borderRight: 0 }}
                    >
                        {data.map((item) => (
                            <Menu.Item
                                key={item.key}
                                onClick={() => handleMenuClick(item.key)}
                            >
                                {item.name}
                            </Menu.Item>
                        ))}
                    </Menu>
                </Sider>
                <Layout style={{ padding: '0 24px 24px' }}>
                    <Content
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}
                    >
                        <h2>{selectedItem.name}</h2>
                        <Table
                            dataSource={selectedItem.scores.sort((a, b) => a.time - b.time)}
                            columns={columns}
                            pagination={false}
                        />
                    </Content>
                </Layout>
            </Layout>
        </>
    );
};

export default Rank;
