export const yanfeiMz = {
    '低于5命': {
        basicRate: {
            z: 2.447,
            x: 2.806,
        },
        basicAtk: 240,
        damageRate: {
            a: 0.24 + 0.05 * 3,
            e: 0.24 + 0.05 * 3,
            q: 0.24 + 0.05 * 3,
            z: 0.24 + 0.05 * 3,
            x: 0.24 + 0.05 * 3,
        },
        zz: 0.544,
    },
    '5命': {
        basicRate: {
            z: 2.447,
            x: 2.806,
        },
        basicAtk: 240,
        damageRate: {
            a: 0.24 + 0.05 * 3,
            e: 0.24 + 0.05 * 3,
            q: 0.24 + 0.05 * 3,
            z: 0.24 + 0.05 * 3,
            x: 0.24 + 0.05 * 3,
        },
        zz: 0.622,
    },
    '6命': {
        basicRate: {
            z: 2.729,
            x: 2.806,
        },
        basicAtk: 240,
        damageRate: {
            a: 0.24 + 0.05 * 4,
            e: 0.24 + 0.05 * 4,
            q: 0.24 + 0.05 * 4,
            z: 0.24 + 0.05 * 4,
            x: 0.24 + 0.05 * 4,
        },
        zz: 0.622,
    },
}

export const yanfeiSyw = {
    '4猎人': {
        damageRate: {
            a: 0.15,
            z: 0.15,
        },
        criticalRate: 0.36,
    },
    '4魔女': {
        damageRate: {
            a: 0.15 * 1.5,
            e: 0.15 * 1.5,
            q: 0.15 * 1.5,
            z: 0.15 * 1.5,
            x: 0.15 * 1.5,
        },
        elementalRate: 0.15,
    },
    '4长夜': {
        damageRate: {
            x: 1,
        },
    },
};

export const yanfeiWeapon = {
    '精1金流': {
        basicAtk: 674,
        damageRate: {
            z: 0.14 * 3,
        },
    },
    '精2金流': {
        basicAtk: 674,
        damageRate: {
            z: 0.175 * 3,
        },
    },
    '精3金流': {
        basicAtk: 674,
        damageRate: {
            z: 0.21 * 3,
        },
    },
    '精4金流': {
        basicAtk: 674,
        damageRate: {
            z: 0.245 * 3,
        },
    },
    '精5金流': {
        basicAtk: 674,
        damageRate: {
            z: 0.28 * 3,
        },
    },
    '精1大典': {
        basicAtk: 542,
        damageRate: {
            z: 0.14 * 3,
        },
    },
    '精2大典': {
        basicAtk: 542,
        damageRate: {
            z: 0.18 * 3,
        },
    },
    '精3大典': {
        basicAtk: 542,
        damageRate: {
            z: 0.22 * 3,
        },
    },
    '精4大典': {
        basicAtk: 542,
        damageRate: {
            z: 0.26 * 3,
        },
    },
    '精5大典': {
        basicAtk: 542,
        damageRate: {
            z: 0.30 * 3,
        },
    },
    '精1溢彩': {
        basicAtk: 674,
        criticalDamage: 0.68,
        damageRate: {},
    },
    '精2溢彩': {
        basicAtk: 674,
        criticalDamage: 0.85,
        damageRate: {},
    },
    '精3溢彩': {
        basicAtk: 674,
        criticalDamage: 1.02,
        damageRate: {},
    },
    '精4溢彩': {
        basicAtk: 674,
        criticalDamage: 1.19,
        damageRate: {},
    },
    '精5溢彩': {
        basicAtk: 674,
        criticalDamage: 1.36,
        damageRate: {},
    },
    '精5流浪（增伤）': {
        basicAtk: 510,
        damageRate: {
            a: 0.96,
            e: 0.96,
            q: 0.96,
            z: 0.96,
            x: 0.96,
        },
    },
    '精5流浪（攻击）': {
        basicAtk: 510,
        atkRate: 1.2,
    },
    '精5流浪（精通）': {
        basicAtk: 510,
        elemental: 480,
    },
};

export const teammates = {
    '班尼特': {
        defaultConfig: {
            mz: '6命',
            weapon: '天空之刃',
            syw: '教官套',
        },
        mz: {
            '0命': {
                selfAtk: 191,
                selfAtkRate: 1.01,
                atkRate: 0.25,
            },
            '1命': {
                selfAtk: 191,
                selfAtkRate: 1.01 + 0.2,
                atkRate: 0.25,
            },
            '5命': {
                selfAtk: 191,
                selfAtkRate: 1.19 + 0.2,
                atkRate: 0.25,
            },
            '6命': {
                selfAtk: 191,
                selfAtkRate: 1.19 + 0.2,
                atkRate: 0.25,
                damageRate: {
                    a: 0.15,
                    e: 0.15,
                    q: 0.15,
                    z: 0.15,
                    x: 0.15,
                },
            },
        },
        weapon: {
            '原木刀': {
                selfAtk: 565,
            },
            '暗巷闪光': {
                selfAtk: 620,
            },
            '天空之刃': {
                selfAtk: 608,
            },
            '风鹰剑': {
                selfAtk: 674,
            },
            '雾切之回光': {
                selfAtk: 674,
            },
        },
        syw: {
            '教官套': {
                elemental: 120,
            },
            '宗室套': {
                atkRate: 0.2,
            },
        },
    },
    '夜兰': {
        defaultConfig: {
            mz: '不区分',
            weapon: '终末',
            syw: '不区分',
        },
        mz: {
            '不区分': {
                damageRate: {
                    a: (0.5 + 0.01) / 2,
                    e: (0.5 + 0.01) / 2,
                    q: (0.5 + 0.01) / 2,
                    z: (0.5 + 0.01) / 2,
                    x: (0.5 + 0.01) / 2,
                },
            },
        },
        weapon: {
            '终末': {
                elemental: 100,
                atkRate: 0.2,
            },
            '非终末': {},
        },
        syw: {
            '不区分': {},
        },
    },
    '万叶': {
        defaultConfig: {
            mz: '0命',
            weapon: '苍古',
            syw: '风套',
        },
        mz: {
            '0命': {
                resistance: -0.4,
                damageRate: 0.4,
            },
            '2命': {
                resistance: -0.4,
                damageRate: 0.4,
                elemental: 200,
            },
        },
        weapon: {
            '苍古': {
                damageRate: {
                    a: 0.16,
                    e: 0,
                    q: 0,
                    z: 0.16,
                    x: 0.16,
                },
                atkRate: 0.2,
            },
        },
        syw: {
            '区分': {},
        },
    },
    '芙宁娜': {
        defaultConfig: {
            mz: '1命',
            weapon: '非板砖',
            syw: '非千岩',
        },
        mz: {
            '0命': {
                damageRate: {
                    a: 0.75,
                    e: 0.75,
                    q: 0.75,
                    z: 0.75,
                    x: 0.75,
                },
            },
            '1命': {
                damageRate: {
                    a: 1,
                    e: 1,
                    q: 1,
                    z: 1,
                    x: 1,
                },
            },
            '3命': {
                damageRate: {
                    a: 1.24,
                    e: 1.24,
                    q: 1.24,
                    z: 1.24,
                    x: 1.24,
                },
            },
        },
        weapon: {
            '板砖': {
                elemental: 100,
            },
            '非板砖': {},
        },
        syw: {
            '千岩': {
                atkRate: 0.2,
            },
            '非千岩': {
            },
        },
    },
    '茜特菈莉': {
        defaultConfig: {
            mz: '2命',
            weapon: '祭星',
            syw: '勇者',
        },
        mz: {
            '0命': {
                resistance: -0.2,
            },
            '1命': {
                resistance: -0.2,
                basicCount: 1000 * 2,
            },
            '2命': {
                resistance: -0.2 - 0.2,
                basicCount: 1000 * 2,
                elemental: 200,
            },
            '6命': {
                resistance: -0.2 - 0.2,
                basicCount: 1000 * 2,
                elemental: 200,
                damageRate: {
                    a: 0.6,
                    e: 0.6,
                    q: 0.6,
                    z: 0.6,
                    x: 0.6,
                },
            },
        },
        weapon: {
            '祭星': {
                damageRate: {
                    a: 0.28,
                    e: 0.28,
                    q: 0.28,
                    z: 0.28,
                    x: 0.28,
                },
            },
            '千叶': {
                elemental: 40,
            },
            '讨龙': {
                atkRate: 0.48,
            },
            '其他': {},
        },
        syw: {
            '千岩': {
                atkRate: 0.2,
            },
            '勇者': {
                damageRate: {
                    a: 0.4,
                    e: 0.4,
                    q: 0.4,
                    z: 0.4,
                    x: 0.4,
                },
            },
        },
    },
    '希诺宁': {
        defaultConfig: {
            mz: '2命',
            weapon: '其他',
            syw: '勇者',
        },
        mz: {
            '0命': {
                resistance: -0.36,
            },
            '2命': {
                resistance: -0.36,
                atkRate: 0.45,
            },
            '3命': {
                resistance: -0.45,
                atkRate: 0.45,
            },
            '4命': {
                resistance: -0.45,
                atkRate: 0.45,
                basicCount: 3500 * 0.65,
            },
        },
        weapon: {
            '岩峰': {
                damageRate: {
                    a: 0.256,
                    e: 0.256,
                    q: 0.256,
                    z: 0.256,
                    x: 0.256,
                },
            },
            '苍古': {
                damageRate: {
                    a: 0.16,
                    e: 0,
                    q: 0,
                    z: 0.16,
                    x: 0.16,
                },
                atkRate: 0.2,
            },
            '其他': {},
        },
        syw: {
            '勇者': {
                damageRate: {
                    a: 0.4,
                    e: 0.4,
                    q: 0.4,
                    z: 0.4,
                    x: 0.4,
                },
            },
            '教官': {
                elemental: 120,
            },
            '其他': {},
        },
    },
    '闲云': {
        defaultConfig: {
            mz: '2命',
            weapon: '鹤鸣',
            syw: '风套',
        },
        mz: {
            '0命': {
                basicCount: 9000,
            },
            '2命': {
                basicCount: 18000,
            },
        },
        weapon: {
            '讨龙': {
                atkRate: 0.48,
            },
            '鹤鸣': {
                damageRate: {
                    x: 0.28,
                },
            },
            '其他': {},
        },
        syw: {
            '风套': {
                resistance: -0.4,
            },
            '宗室': {
                atkRate: 0.2,
            },
            '其他': {},
        },
    },
    '伊安珊': {
        defaultConfig: {
            mz: '6命',
            weapon: '不区分',
            syw: '宗室',
        },
        mz: {
            '0命': {
                atk: 690,
            },
            '2命': {
                atk: 690,
                atkRate: 0.3,
            },
            '6命': {
                atk: 690,
                atkRate: 0.3,
                damageRate: {
                    a: 0.25,
                    e: 0.25,
                    q: 0.25,
                    z: 0.25,
                    x: 0.25,
                },
            },
        },
        weapon: {
            '不区分': {

            },
        },
        syw: {
            '勇者': {
                damageRate: {
                    a: 0.4,
                    e: 0.4,
                    q: 0.4,
                    z: 0.4,
                    x: 0.4,
                },
            },
            '宗室': {
                atkRate: 0.2,
            },
            '教官': {
                elemental: 120,
            },
            '其他': {},
        },
    },
}