import React from 'react'
import styles from './index.module.css'

const Item = (props) => {
    const { type, name, level } = props;
    return (
        <div className={styles.item}>
            <img className={styles.img} src={`/${type === 0 ? 'characters' : 'weapons'}/${name}.png`} alt={name} />
            <div className={styles.level}>{level}</div>
        </div>
    )
}

const Team = (props) => {
    const { data } = props;
    return (
        <div className={styles.container}>
            <div className={styles.character}>
                {data.map((item) => (
                    <Item type={0} name={item[0]} level={item[1]} />
                ))}
            </div>
            <div className={styles.weapon}>
                {data.map((item) => (
                    <Item type={1} name={item[2]} level={item[3]} />
                ))}
            </div>
        </div>
    )
}

export default Team