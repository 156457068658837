import React, { useMemo, useState } from 'react';
import { Row, Col, Select, Table, InputNumber } from 'antd';
import { cloneDeep } from 'lodash';
import { draw11Info, engine11Info, driver11Info, driver5placeInfo, teammatesInfo } from '../../eleven.data';
import styles from './index.module.css';

const Eleven = () => {
    const [elevenConfig, setElevenConfig] = useState({
        draw: '0影',
        driver: '2鸟+4火',
        driver5place: '5号位火伤',
        driverSubstatsAtkRate: 6,
        driverSubstatsCritical: 25,
        a: 2,
        e: 1,
        q: 1,
        z: 1,
        engine: [
            '1阶硫磺石',
            '5阶硫磺石',
            '1阶心弦',
            '5阶心弦',
            '5阶鎏金',
        ],
    });

    const [teamConfig, setTeamConfig] = useState([
        { type: '耀嘉音', draw: '0影', engine: '专武1阶' },
        { type: '莱特', draw: '0影', engine: '专武1阶' }
    ]);

    const [enemyConfig, setEnemyConfig] = useState({
        resistance: 20,
    });

    const damageData = useMemo(() => {
        const draw11Data = draw11Info[elevenConfig.draw];
        const driver11Data = driver11Info[elevenConfig.driver];
        const driver5placeData = driver5placeInfo[elevenConfig.driver5place];
        const teammateData = teamConfig.map(member => {
            const drawData = teammatesInfo[member.type].draw[member.draw];
            const engineData = teammatesInfo[member.type].engine[member.engine];
            return {
                name: member.type,
                drawData,
                engineData,
            };
        });

        const configs = [
            draw11Data,
            driver11Data,
            driver5placeData,
            teammateData[0].drawData,
            teammateData[0].engineData,
            teammateData[1].drawData,
            teammateData[1].engineData,
            {
                outerAtkRate: 0.03 * elevenConfig.driverSubstatsAtkRate,
                critical: 0.048 * elevenConfig.driverSubstatsCritical,
            },
        ];

        return elevenConfig.engine.map(item => {
            const basicData = cloneDeep(engine11Info[item]);
            for (const config of configs) {
                Object.keys(config).forEach(key => {
                    if (typeof config[key] === 'number') {
                        basicData[key] = (basicData[key] || 0) + config[key];
                    } else if (typeof config[key] === 'object') {
                        if (!basicData[key]) {
                            basicData[key] = {};
                        }
                        Object.keys(config[key]).forEach(subKey => {
                            basicData[key][subKey] = (basicData[key][subKey] || 0) + config[key][subKey];
                        });
                    }
                });
            }
            return {
                name: item,
                data: basicData,
            };
        });
    }, [elevenConfig, teamConfig, enemyConfig]);

    const data = useMemo(() => {
        return damageData.map(item => {
            const finalAtk = (item.data.basicAtk * (1 + item.data.outerAtkRate) + item.data.outerAtk) * (1 + (item.data.innerAtkRate || 0)) + item.data.innerAtk;
            const finalCritical = item.data.critical >= 4 ? item.data.critical - 1 : 1 + item.data.critical * item.data.critical / 8;
            const finalAResistance = Math.min(-enemyConfig.resistance / 100 - item.data.resistance.a, 1);
            const finalEResistance = Math.min(-enemyConfig.resistance / 100 - item.data.resistance.e, 1);
            const finalQResistance = Math.min(-enemyConfig.resistance / 100 - item.data.resistance.q, 1);
            const finalZResistance = Math.min(-enemyConfig.resistance / 100 - item.data.resistance.z, 1);
            const finalADamageRate = item.data.damageRate.a;
            const finalEDamageRate = item.data.damageRate.e;
            const finalQDamageRate = item.data.damageRate.q;
            const finalZDamageRate = item.data.damageRate.z;
            const finalDamage = finalAtk * finalCritical * (
                (1 + finalAResistance) * finalADamageRate * 13.845 * 2 * elevenConfig.a
                + (1 + finalEResistance) * finalEDamageRate * 15.96 * elevenConfig.e
                + (1 + finalQResistance) * finalQDamageRate * 49.71 * elevenConfig.q
                + (1 + finalZResistance) * finalZDamageRate * 14.95 * elevenConfig.z
            )
            return {
                engine: item.name,
                damage: finalDamage,
            };
        })
    }, [elevenConfig, damageData, enemyConfig]);

    return (
        <Row gutter={24} style={{ padding: 20 }}>
            <Col span={8}>
                <div className={styles.first}>
                    <div className={styles.title}>11号配置</div>
                    <div className={styles.background} />
                    <div className={styles.subtitle}>
                        影画
                    </div>
                    <Select
                        value={elevenConfig.draw}
                        onChange={draw => setElevenConfig(c => ({ ...c, draw }))}
                        className={styles.select}
                        style={{ width: '100%' }}
                    >
                        {Object.keys(draw11Info).map(key => (
                            <Select.Option key={key} value={key}>{key}</Select.Option>
                        ))}
                    </Select>

                    <div className={styles.subtitle}>
                        驱动盘套装
                    </div>
                    <Select
                        value={elevenConfig.driver}
                        onChange={driver => setElevenConfig(c => ({ ...c, driver }))}
                        className={styles.select}
                        style={{ width: '100%' }}
                    >
                        {Object.keys(driver11Info).map(key => (
                            <Select.Option key={key} value={key}>{key}</Select.Option>
                        ))}
                    </Select>

                    <div className={styles.subtitle}>
                        驱动盘5号位
                    </div>
                    <Select
                        value={elevenConfig.driver5place}
                        onChange={driver5place => setElevenConfig(c => ({ ...c, driver5place }))}
                        className={styles.select}
                        style={{ width: '100%' }}
                    >
                        {Object.keys(driver5placeInfo).map(key => (
                            <Select.Option key={key} value={key}>{key}</Select.Option>
                        ))}
                    </Select>

                    <div className={styles.subtitle}>
                        驱动盘攻击副词条
                    </div>
                    <InputNumber
                        min={0}
                        value={elevenConfig.driverSubstatsAtkRate}
                        onChange={driverSubstatsAtkRate => setElevenConfig(c => ({ ...c, driverSubstatsAtkRate }))}
                        suffix={'* 3%'}
                        className={styles.select}
                        style={{ width: '100%' }}
                    />
                    <div className={styles.subtitle}>
                        驱动盘双暴副词条
                    </div>
                    <InputNumber
                        min={0}
                        value={elevenConfig.driverSubstatsCritical}
                        onChange={driverSubstatsCritical => setElevenConfig(c => ({ ...c, driverSubstatsCritical }))}
                        suffix={'* 0.048%'}
                        className={styles.select}
                        style={{ width: '100%' }}
                    />

                    <div className={styles.subtitle}>
                        强化特殊技
                    </div>
                    <InputNumber
                        min={0}
                        value={elevenConfig.e}
                        onChange={e => setElevenConfig(c => ({ ...c, e }))}
                        suffix={'次'}
                        className={styles.select}
                        style={{ width: '100%' }}
                    />

                    <div className={styles.subtitle}>
                        终结技
                    </div>
                    <InputNumber
                        min={0}
                        value={elevenConfig.q}
                        onChange={q => setElevenConfig(c => ({ ...c, q }))}
                        suffix={'次'}
                        className={styles.select}
                        style={{ width: '100%' }}
                    />

                    <div className={styles.subtitle}>
                        连携技
                    </div>
                    <InputNumber
                        min={0}
                        value={elevenConfig.z}
                        onChange={z => setElevenConfig(c => ({ ...c, z }))}
                        suffix={'次'}
                        className={styles.select}
                        style={{ width: '100%' }}
                    />

                    <div className={styles.subtitle}>
                        火刀8段
                    </div>
                    <InputNumber
                        min={0}
                        max={elevenConfig.e + elevenConfig.q + elevenConfig.z}
                        value={elevenConfig.a}
                        onChange={a => setElevenConfig(c => ({ ...c, a }))}
                        suffix={'组'}
                        className={styles.select}
                        style={{ width: '100%' }}
                    />

                    <div className={styles.subtitle}>
                        对比音擎
                    </div>
                    <Select
                        mode={'multiple'}
                        multiple
                        value={elevenConfig.engine}
                        onChange={engine => setElevenConfig(c => ({ ...c, engine }))}
                        className={styles.select}
                        style={{ width: '100%' }}
                    >
                        {Object.keys(engine11Info).map(key => (
                            <Select.Option key={key} value={key}>{key}</Select.Option>
                        ))}
                    </Select>
                </div>
            </Col>

            <Col span={8}>
                {teamConfig.map((member, index) => (
                    <div key={member.type} style={{ marginBottom: 26 }}>
                        <div className={styles.title}>队友{index + 1}配置</div>

                        <div className={styles.subtitle}>角色</div>
                        <Select
                            value={member.type}
                            onChange={type => {
                                const newTeam = [...teamConfig];
                                newTeam[index].type = type;
                                newTeam[index].draw = Object.keys(teammatesInfo[type].draw)[0];
                                newTeam[index].engine = Object.keys(teammatesInfo[type].engine)[0];
                                setTeamConfig(newTeam);
                            }}
                            className={styles.select}
                            style={{ width: '100%' }}
                        >
                            {Object.keys(teammatesInfo).filter(t => t !== teamConfig[1 - index].type)
                                .map(type => (
                                    <Select.Option key={type} value={type}>{type}</Select.Option>
                                ))}
                        </Select>

                        <div className={styles.subtitle}>{member.type}影画</div>
                        <Select
                            value={member.draw}
                            onChange={draw => {
                                const newTeam = [...teamConfig];
                                newTeam[index].draw = draw;
                                setTeamConfig(newTeam);
                            }}
                            className={styles.select}
                            style={{ width: '100%' }}
                        >
                            {Object.keys(teammatesInfo[member.type].draw)
                                .map(key => (
                                    <Select.Option key={key} value={key}>{key}</Select.Option>
                                ))}
                        </Select>

                        <div className={styles.subtitle}>{member.type}音擎</div>
                        <Select
                            value={member.engine}
                            onChange={engine => {
                                const newTeam = [...teamConfig];
                                newTeam[index].engine = engine;
                                setTeamConfig(newTeam);
                            }}
                            className={styles.select}
                            style={{ width: '100%' }}
                        >
                            {Object.keys(teammatesInfo[member.type].engine)
                                .map(key => (
                                    <Select.Option key={key} value={key}>{key}</Select.Option>
                                ))}
                        </Select>
                    </div>
                ))}
                <div className={styles.title}>敌人配置</div>
                <div className={styles.subtitle}>抗性</div>
                <InputNumber
                    min={-20}
                    max={100}
                    value={enemyConfig.resistance}
                    onChange={resistance => setEnemyConfig({ resistance: resistance })}
                    suffix={'%'}
                    className={styles.select}
                    style={{ width: '100%' }}
                />
            </Col>

            <Col span={8}>
                <div className={styles.title}>音擎伤害排名（以1阶硫磺石为基准）</div>
                <Table
                    columns={[
                        { title: '音擎', dataIndex: 'engine' },
                        { title: '伤害比例', dataIndex: 'ratio' }
                    ]}
                    dataSource={data.map(item => ({
                        ...item,
                        ratio: item.damage / data[0].damage,
                    })).sort((a, b) => b.ratio - a.ratio)}
                    pagination={false}
                    size="small"
                    style={{ marginBottom: 24 }}
                />
                <div className={styles.title}>最终局内属性</div>
                {
                    damageData.map(item => (
                        <>
                            <div key={item.name} className={styles.subtitle}>
                                {item.name}
                            </div>
                            <div className={styles.line}>
                                基础攻击：{item.data.basicAtk}
                            </div>
                            <div className={styles.line}>
                                局外攻击：{item.data.basicAtk * (1 + item.data.outerAtkRate) + item.data.outerAtk}
                            </div>
                            <div className={styles.line}>
                                局内攻击：{(item.data.basicAtk * (1 + item.data.outerAtkRate) + item.data.outerAtk) * (1 + (item.data.innerAtkRate || 0)) + item.data.innerAtk}
                            </div>
                            <div className={styles.line}>
                                暴击率：{item.data.critical >= 4 ? '100%' : `${item.data.critical * 25}%`}
                            </div>
                            <div className={styles.line}>
                                暴击伤害：{item.data.critical >= 4 ? `${item.data.critical * 100 - 200}%` : `${item.data.critical * 50}%`}
                            </div>
                            <div className={styles.line}>
                                强化特殊技抗性：{enemyConfig.resistance + item.data.resistance.e * 100}%
                            </div>
                            <div className={styles.line}>
                                终结技抗性：{enemyConfig.resistance + item.data.resistance.q * 100}%
                            </div>
                            <div className={styles.line}>
                                连携技抗性：{enemyConfig.resistance + item.data.resistance.z * 100}%
                            </div>
                            <div className={styles.line}>
                                普攻抗性：{enemyConfig.resistance + item.data.resistance.a * 100}%
                            </div>
                            <div className={styles.line}>
                                强化特殊技伤害加成：{item.data.damageRate.e * 100 - 100}%
                            </div>
                            <div className={styles.line}>
                                终结技伤害加成：{item.data.damageRate.q * 100 - 100}%
                            </div>
                            <div className={styles.line}>
                                连携技伤害加成：{item.data.damageRate.z * 100 - 100}%
                            </div>
                            <div className={styles.line} style={{ marginBottom: 16 }}>
                                普攻伤害加成：{item.data.damageRate.a * 100 - 100}%
                            </div>
                        </>
                    ))
                }
            </Col>
        </Row>
    );
};

export default Eleven;