import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Rank from './pages/rank';
import Calc from './pages/calc';
import Eleven from './pages/eleven';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* 根路由重定向到 /rank */}
        <Route path="/" element={<Navigate to="/rank" />} />

        {/* 配置 /rank 路由 */}
        <Route path="/rank" element={<Rank />} />

        {/* 配置 /calc 路由 */}
        <Route path="/calc" element={<Calc />} />

        {/* 配置 /eleven 路由 */}
        <Route path="/eleven" element={<Eleven />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
